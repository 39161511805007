import { Link } from '@remix-run/react'
import { cn } from '#app/utils/misc'
import { type NavigationCategoriesStoryblok } from '#types/component-types-sb'
import SubMenu from './submenu'

interface NavItemProps {
	link: string
	name: string
	isActive: boolean
	itemNavigationData?: NavigationCategoriesStoryblok
}

const NavItem = ({
	link,
	name,
	isActive,
	itemNavigationData,
}: NavItemProps) => {
	return (
		<div key={link} className={cn('group hidden md:block')}>
			<div className={cn('peer', isActive && 'font-bold')}>
				<Link to={link} prefetch="intent" className="text-[18px]">
					{name}
				</Link>
				<div
					className={cn(
						'h-[2px] w-0 rounded bg-beige-90 transition-all group-hover:w-full',
						isActive && 'w-full',
					)}
				/>
			</div>
			{/* If we have data for this navItem on Storyblok we are going to display the submenu */}
			{itemNavigationData && <SubMenu content={itemNavigationData} />}
		</div>
	)
}

export default NavItem
