import { Link, useLocation } from '@remix-run/react'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ClerkSearchBar } from '#app/features/clerk-search/clerk-search-bar'
import MobileSearchDrawer from '#app/features/clerk-search/search-drawer'
import SearchOverlay from '#app/features/clerk-search/search-overlay'
import { useCartStore } from '#app/store/cart'
import { cn } from '#app/utils/misc'
import { type NavigationCategoriesStoryblok } from '#types/component-types-sb'
import ButtonAndLinks from '../typography/button-and-links'
import { Icon } from '../ui/icon'
import NavItem from './nav-item/index'
import HeaderKeysRow, {
	getKidsCategories,
	getMenCategories,
	getWomenCategories,
} from './quick-links-header'
import ResponsiveNavbar from './responsive-navbar/index'

const genderKeys = ['women', 'men', 'kids']

export default function Navbar({
	navigationData,
	sessionLanguage,
	onSaleWomen,
	onSaleMen,
	onSaleKids,
}: {
	navigationData: NavigationCategoriesStoryblok[]
	sessionLanguage: string
	onSaleWomen: boolean
	onSaleMen: boolean
	onSaleKids: boolean
}) {
	let { t } = useTranslation('navbar')
	const location = useLocation()
	const navItems = [
		{ link: '/women-landing', name: t('women_header') },
		{ link: '/men-landing', name: t('men_header') },
		{ link: '/kids-landing', name: t('kids_header') },
		{ link: '/brands', name: t('brands_header') },
		{ link: '/stores', name: t('stores_header') },
	]
	const [lastVisitedLanding, setLastVisitedLanding] = useState('/')

	const basePath =
		genderKeys.find(keyword => lastVisitedLanding.includes(keyword)) || ''
	// Get the categories for each group
	const womenCategories = getWomenCategories(t, onSaleWomen)
	const menCategories = getMenCategories(t, onSaleMen)
	const kidsCategories = getKidsCategories(t, onSaleKids)

	let headerQuickLinks
	if (basePath === 'women') {
		headerQuickLinks = womenCategories
	} else if (basePath === 'men') {
		headerQuickLinks = menCategories
	} else {
		headerQuickLinks = kidsCategories
	}

	const { count } = useCartStore()
	let language = sessionLanguage
	const [isDrawerOpen, setDrawerOpen] = useState(false) // This controls the mobile drawer
	const [isSearchOverlayVisible, setSearchOverlayVisible] = useState(false)
	const toggleSearchOverlay = useCallback(() => {
		setSearchOverlayVisible(prev => !prev)
	}, [])

	useEffect(() => {
		const isLandingPage = genderKeys.some(keyword =>
			location.pathname.includes(keyword),
		)
		if (isLandingPage) {
			const landingPage = (keyword: string | undefined) => {
				switch (keyword) {
					case 'women':
						return '/women-landing'
					case 'men':
						return '/men-landing'
					case 'kids':
						return '/kids-landing'
					default:
						return '/'
				}
			}
			const keyword = genderKeys.find(keyword =>
				location.pathname.includes(keyword),
			)
			setLastVisitedLanding(landingPage(keyword))
		}
	}, [location.pathname])

	useEffect(() => {
		const handleGlobalKeyDown = (event: KeyboardEvent) => {
			if (
				(event.key === 's' && event.ctrlKey) ||
				(event.key === 'k' && event.metaKey)
			) {
				toggleSearchOverlay()
			}
		}

		document.addEventListener('keydown', handleGlobalKeyDown)
		return () => {
			document.removeEventListener('keydown', handleGlobalKeyDown)
		}
	}, [toggleSearchOverlay])

	const toggleDrawer = () => {
		setDrawerOpen(!isDrawerOpen) // Function to toggle the drawer state
	}

	const isActive = (name: string) => {
		let pathNameWords = location.pathname.split('/')
		if (language === 'da') {
			switch (name) {
				case 'Kvinder':
					return pathNameWords.some(word =>
						word.split('-').some(w => w === 'women'),
					)
				case 'Mænd':
					return pathNameWords.some(word =>
						word.split('-').some(w => w === 'men'),
					)
				case 'Børn':
					return pathNameWords.some(word =>
						word.split('-').some(w => w === 'kids'),
					)
				case 'Butikker':
					return pathNameWords.some(word =>
						word.split('-').some(w => w === 'stores'),
					)
			}
		}
		return pathNameWords.some(word =>
			word.split('-').some(w => w === name.toLowerCase()),
		)
	}
	return (
		<header className="relative z-30 bg-white px-4 py-4 shadow md:px-14">
			<nav>
				<div className="flex flex-col gap-4 md:gap-0">
					<div className="flex items-center gap-1 md:gap-10">
						{!isSearchOverlayVisible && (
							<div className="flex flex-grow gap-6 transition-all md:gap-10">
								<Link
									to={lastVisitedLanding}
									aria-label="Return to the last visited landing page"
								>
									<Icon
										className="text-body-md h-[22px] w-[72px]"
										name="plenty"
										aria-hidden="true"
									/>
								</Link>
								{navItems.map(({ link, name }) => {
									const itemNavigationData = navigationData.find(
										(item: any) => item.title === name,
									)
									return (
										<NavItem
											key={link}
											link={link}
											name={name}
											isActive={isActive(name)}
											itemNavigationData={itemNavigationData}
										/>
									)
								})}
							</div>
						)}
						<div className="hidden items-center gap-5 md:flex">
							<div onClick={toggleSearchOverlay} className="cursor-pointer">
								{!isSearchOverlayVisible && (
									<ClerkSearchBar
										searchTerm={''}
										onSearchChange={newSearchTerm => newSearchTerm}
									/>
								)}
							</div>
						</div>
						{!isSearchOverlayVisible && (
							<div className="flex items-center gap-5">
								<div className="relative">
									<Link
										to={'/shopping-bag'}
										className="relative"
										aria-label={`Shopping bag with ${count()} items`}
									>
										{count() > 0 && (
											<span className="absolute right-0 top-0 inline-flex -translate-y-1/2 translate-x-1/2 transform items-center justify-center rounded-full bg-mint-green-80p px-2 py-1 text-xs font-bold leading-none text-black-90">
												{count()}
											</span>
										)}
										<Icon
											className="text-body-md"
											size="lg"
											name="cart"
											aria-hidden="true"
										/>
									</Link>
								</div>
								<ResponsiveNavbar
									navItems={navItems.slice(0, navItems.length - 2)}
									navigationData={navigationData}
								></ResponsiveNavbar>
							</div>
						)}
					</div>

					{!isSearchOverlayVisible && (
						<div
							className={cn(
								'z-50 hidden items-center justify-between transition-all md:flex ',
								shouldShowQuickLinks(location.pathname, lastVisitedLanding)
									? 'md:mt-4 md:h-full'
									: 'invisible opacity-0 md:h-0',
							)}
						>
							<HeaderKeysRow keys={headerQuickLinks} />
							<ButtonAndLinks as="xs" body={t('header_shop_now')} />
						</div>
					)}
				</div>

				<div className="mx-auto max-w-full pt-4 md:hidden">
					<div className="cursor-pointer">
						<ClerkSearchBar
							searchTerm={''}
							onSearchChange={newSearchTerm => newSearchTerm}
							onClick={toggleDrawer}
						/>
					</div>
				</div>
			</nav>
			<div className="mx-auto flex w-full flex-col justify-between gap-2 bg-white">
				{isDrawerOpen && (
					<MobileSearchDrawer
						setOpen={setDrawerOpen}
						open={isDrawerOpen}
						lastVisitedLanding={lastVisitedLanding}
					/>
				)}
			</div>
			{isSearchOverlayVisible && (
				<div className="hidden md:block">
					<SearchOverlay
						closeOverlay={() => setSearchOverlayVisible(false)}
						lastVisitedLanding={lastVisitedLanding}
					/>
				</div>
			)}
		</header>
	)
}

const shouldShowQuickLinks = (pathname: string, lastVisitedLanding: string) => {
	// should display just on the pathnames that include this genderKeys and product (pdp)
	const isProductPage = pathname.includes('/product/')
	const isGenderPage = genderKeys.some(keyword => pathname.includes(keyword))

	return isProductPage || isGenderPage
}
