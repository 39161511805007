import { useTranslation } from 'react-i18next'
import { Body } from '#app/components/typography/index'

const NoResultFound = ({ searchTerm }: any) => {
	let { t } = useTranslation('search')
	return (
		<div className="flex flex-col gap-6">
			<div className="flex flex-col gap-2">
				<Body as="m" className="text-beige-100">
					{t('search_no_result')}
				</Body>
				<Body as="l" className="font-bold">
					‘{searchTerm}’
				</Body>
			</div>
			<div className="flex flex-col gap-2">
				<div>{t('search_try')}</div>
				<div className="flex flex-col">
					<div className="flex items-center">
						<div className=" flex px-[4px]">&#8226;</div>
						<Body as="m">{t('search_double_check')} </Body>
					</div>
					<div className="flex items-center">
						<div className=" flex px-[4px]">&#8226;</div>
						<Body as="m">{t('search_again')}</Body>
					</div>
					<div className="flex items-center">
						<div className=" flex px-[4px]">&#8226;</div>
						<Body as="m">{t('search_explore_category')}</Body>
					</div>
				</div>
			</div>
		</div>
	)
}

export default NoResultFound
