import { Link } from '@remix-run/react'
import { renderRichText } from '@storyblok/react'
import { useState } from 'react'
import { type StoryblokRichtext } from 'storyblok-rich-text-react-renderer'
import renderEmojis from './storyblok/emoji-renderer'
import { Icon } from './ui/icon'

type Announcement = {
	component: string
	textField: StoryblokRichtext
	textColor?: string
	backgroundColor?: string
	navigateUrlToAnnouncement?: string
	_uid: string
} | null

type AnnouncementBannerProps = {
	announcement: Announcement

	bannerVisible: boolean
}

const AnnouncementBanner = ({
	announcement,
	bannerVisible,
}: AnnouncementBannerProps) => {
	const [isVisible, setIsVisible] = useState<boolean>(bannerVisible)
	const dynamicStyles = {
		...(announcement?.backgroundColor && {
			backgroundColor: announcement.backgroundColor,
		}),
		...(announcement?.textColor && { color: announcement.textColor }),
	}
	const handleCloseClick = () => {
		if (typeof document !== 'undefined') {
			setIsVisible(false)
			document.cookie = 'bannerVisible=false; path=/; max-age=21600' // Expires in 6 hours
		}
	}

	if (!isVisible) {
		return null
	}

	const announcementHTML = renderRichText(announcement?.textField)

	function BodyContent() {
		return (
			<>
				<div className="flex w-full flex-col items-center justify-center text-center text-[16px] leading-[1.375rem] tracking-[-0.24px] text-white md:flex-row">
					{renderEmojis(announcementHTML, dynamicStyles)}
				</div>
			</>
		)
	}

	return (
		<div
			className="flex w-full cursor-pointer items-center gap-4 bg-black py-2 pl-[37px] pr-4 md:px-[76px]"
			style={dynamicStyles}
		>
			{announcement?.navigateUrlToAnnouncement ? (
				<Link
					to={announcement.navigateUrlToAnnouncement}
					className="flex w-full"
					style={dynamicStyles}
				>
					<BodyContent />
				</Link>
			) : (
				<BodyContent />
			)}
			<Icon
				className="text-body-md ml-auto self-start"
				style={dynamicStyles}
				size="lg"
				name="cross"
				onClick={handleCloseClick}
			/>
		</div>
	)
}

export default AnnouncementBanner
