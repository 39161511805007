import { Link } from '@remix-run/react'
import React from 'react'
import ButtonAndLinks from '../typography/button-and-links'

const HeaderKeysRow = ({
	keys,
}: {
	keys: { link: string; name: string; isSale?: boolean }[]
}) => {
	return (
		<div className="group flex gap-6">
			{keys.map(({ link, name, isSale }) => (
				<Link key={link} to={link}>
					<ButtonAndLinks
						as="xs"
						body={name}
						className={`border-b border-b-transparent hover:border-black-90 focus:border-mint-green-50 ${
							isSale ? 'text-red-90' : ''
						}`}
					/>
				</Link>
			))}
		</div>
	)
}

export default HeaderKeysRow

export const getWomenCategories = (
	t: (arg0: string) => string,
	onSaleWomen: boolean,
) => [
	{ link: `/products/women?sortingOrder=new`, name: t('header_new') },
	{ link: `/products/women?sortingOrder=popular`, name: t('header_popular') },
	{ link: `/products/women-clothing`, name: t('header_clothing') },
	{
		link: `/products/women-clothing-dressesandskirts`,
		name: t('header_dresses'),
	},
	{
		link: `/products/women-clothing-tshirtsandtops-tops`,
		name: t('header_tops'),
	},
	{
		link: `/products/women-clothing-trousersandshorts-shorts`,
		name: t('header_shorts'),
	},
	{
		link: `/products/women-accessoriesandshoes`,
		name: t('header_accessories'),
	},
	...(onSaleWomen
		? [
				{
					link: `/products/women?sortingOrder=on_sale`,
					name: t('header_sale'),
					isSale: true,
				},
		  ]
		: []),
]

export const getMenCategories = (
	t: (arg0: string) => string,
	onSaleMen: boolean,
) => [
	{ link: `/products/men?sortingOrder=new`, name: t('header_new') },
	{ link: `/products/men?sortingOrder=popular`, name: t('header_popular') },
	{ link: `/products/men-clothing`, name: t('header_clothing') },
	{ link: `/products/men-clothing-shirts`, name: t('header_shirts') },
	{
		link: `/products/men-clothing-trousersandshorts-shorts`,
		name: t('header_shorts'),
	},
	{
		link: `/products/men-accessoriesandshoes`,
		name: t('header_accessories'),
	},
	{
		link: `/products/men-accessoriesandshoes-shoes`,
		name: t('header_shoes'),
	},
	...(onSaleMen
		? [
				{
					link: `/products/men?sortingOrder=on_sale`,
					name: t('header_sale'),
					isSale: true,
				},
		  ]
		: []),
]

export const getKidsCategories = (
	t: (arg0: string) => string,
	onSaleKids: boolean,
) => [
	{ link: `/products/kids?sortingOrder=new`, name: t('header_new') },
	{ link: `/products/kids?sortingOrder=popular`, name: t('header_popular') },
	{ link: `/products/kids-baby_0_2`, name: t('header_baby') },
	{ link: `/products/kids-girl_2_8`, name: t('header_girl_2_8') },
	{ link: `/products/kids-boy_2_8`, name: t('header_boy_2_8') },
	{ link: `/products/kids-girl_9_16`, name: t('header_girl_9_16') },
	{ link: `/products/kids-boy_9_16`, name: t('header_boy_9_16') },
	...(onSaleKids
		? [
				{
					link: `/products/kids?sortingOrder=on_sale`,
					name: t('header_sale'),
					isSale: true,
				},
		  ]
		: []),
]
