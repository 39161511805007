import { useTranslation } from 'react-i18next'
import { Heading } from '#app/components/typography/index'
import { Icon } from '#app/components/ui/icon'
import { cn } from '#app/utils/misc'
import {
	type CategoryStoryblok,
	type NavigationCategoriesStoryblok,
	type ParentCategoryStoryblok,
} from '#types/component-types-sb'
import { ResponsiveNavLinkItem } from './responsive-nav-item'
import { ResponsiveOptions } from './index'

interface SubNavigationMenuProps {
	selectedOption: ResponsiveOptions
	openNavigationContent?: NavigationCategoriesStoryblok
	handleOptionClick: (option: ResponsiveOptions) => void
	selectedCategory: string | null
	setSelectedCategory: (uid: string | null) => void
}

const SubNavigationMenu = ({
	selectedOption,
	openNavigationContent,
	handleOptionClick,
	selectedCategory,
	setSelectedCategory,
}: SubNavigationMenuProps) => {
	let { t } = useTranslation('navbar')

	const renderMainCategories = (
		categories: (CategoryStoryblok | ParentCategoryStoryblok)[] | undefined,
	) => {
		return categories?.map(category => (
			<div
				key={category._uid}
				className="cursor-pointer"
				onClick={() => setSelectedCategory(category.title)}
			>
				<ResponsiveNavLinkItem link="#" name={category.title} />
			</div>
		))
	}

	const renderSubcategories = (
		subcategories: (CategoryStoryblok | ParentCategoryStoryblok)[],
	) => {
		return subcategories?.map(({ title, link, _uid }) => (
			<ResponsiveNavLinkItem
				key={_uid}
				link={link?.cached_url || ''}
				name={title}
			/>
		))
	}

	const handleBackClick = () => {
		setSelectedCategory(null) // Clear selected category to show main categories
	}

	return (
		<div
			className={cn(
				'flex h-full w-full flex-col py-6',
				selectedOption !== ResponsiveOptions.Main &&
					selectedOption !== ResponsiveOptions.Language
					? 'flex'
					: 'hidden',
			)}
		>
			{selectedCategory == null ? (
				<>
					<div
						className="flex cursor-pointer"
						onClick={() => handleOptionClick(ResponsiveOptions.Main)}
					>
						<Icon
							name="right-arrow"
							size="xl"
							className="mr-1 rotate-180 transform"
						/>
						<Heading as="h4">
							{t('back_to_option')}
							<span> {selectedOption}</span>
						</Heading>
					</div>
					{renderMainCategories(openNavigationContent?.categories)}
				</>
			) : (
				// If a category is selected, render its subcategories
				<>
					<div className="flex cursor-pointer" onClick={handleBackClick}>
						<Icon
							name="right-arrow"
							size="xl"
							className="mr-1 rotate-180 transform"
						/>
						<Heading as="h4">
							{t('back_to_option')}
							<span> {selectedCategory}</span>
						</Heading>
					</div>
					<div className="overflow-y-auto">
						{renderSubcategories(
							openNavigationContent?.categories?.find(
								category => category.title === selectedCategory,
							)?.categories || [],
						)}
					</div>
				</>
			)}
		</div>
	)
}

export default SubNavigationMenu
