import { Form, useSubmit } from '@remix-run/react'
import { useTranslation } from 'react-i18next'
import Heading from '#app/components/typography/heading'
import { type IconName } from '#app/components/ui/icon'
import { getLanguageShortHand } from '#app/utils/localisation/lokalisation-utilities'
import { cn } from '#app/utils/misc'
import { ResponsiveNavItem } from './responsive-nav-item'
import { ResponsiveOptions } from './index'

interface LanguageMenuProps {
	selectedOption: ResponsiveOptions
	handleOptionClick: (option: ResponsiveOptions) => void
}

const LanguageMenu = ({
	selectedOption,
	handleOptionClick,
}: LanguageMenuProps) => {
	const languages: { name: string; icon: IconName }[] = [
		{ name: 'English', icon: 'flags/uk' },
		{ name: 'Dansk', icon: 'flags/dk' },
	]
	const submit = useSubmit()
	let { t, i18n } = useTranslation('common')

	const handleSubmit = async (formData: FormData) => {
		try {
			await submit(formData, {
				method: 'POST',
				action: '/resources/language',
			})
		} catch (error) {
			console.log(error)
			console.error(error)
		}
	}

	const handleOnClick = async (language: string) => {
		i18n.changeLanguage(getLanguageShortHand(language))
		const formData = new FormData()
		formData.append('language', language)
		await handleSubmit(formData)
		handleOptionClick(ResponsiveOptions.Main)
	}

	return (
		<Form
			className={cn(
				'flex h-full w-full flex-col  py-6',
				selectedOption === ResponsiveOptions.Language ? 'flex' : 'hidden',
			)}
		>
			<Heading as={'h4'} heading={t('select_language')} />
			{languages.map(({ name, icon }) => (
				<ResponsiveNavItem
					key={name}
					name={name}
					iconName={icon}
					handleOptionClick={handleOnClick}
					isActive={getLanguageShortHand(name) == i18n.language}
				></ResponsiveNavItem>
			))}
		</Form>
	)
}

export default LanguageMenu
