import { Icon } from '#app/components/ui/icon'
import { Input } from '#app/components/ui/input'
import { Label } from '#app/components/ui/label'
import { cn } from '#app/utils/misc'

interface ClerkSearchInputProps extends React.HTMLAttributes<HTMLInputElement> {
	value: string
	placeholder: string
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
	onFocus?: () => void
	onBlur?: () => void
	onClick?: () => void
	onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void // Add onKeyDown
	closeSearch?: boolean
	onCloseSearch?: () => void
}

const ClerkSearchInput = ({
	id,
	className,
	value,
	placeholder,
	onChange,
	onFocus,
	onBlur,
	onClick,
	onKeyDown, // Include onKeyDown in the destructured props
	closeSearch,
	onCloseSearch,
	...rest
}: ClerkSearchInputProps) => {
	return (
		<div className="flex-1 rounded-3xl">
			<Label htmlFor={id} className="sr-only">
				{id || 'Search'}
			</Label>
			<div
				className={cn(
					'relative flex w-full items-center rounded-3xl md:min-w-[400px]',
					className,
				)}
			>
				<Icon
					className="text-body-md absolute left-4"
					size="lg"
					name="magnifying-glass"
				/>
				<Input
					type="text"
					name="terms"
					value={value}
					placeholder={placeholder}
					className={cn(
						'w-full rounded-3xl border border-beige-90 border-input bg-white py-4 pl-12 pr-6 ring-offset-background placeholder:text-muted-foreground placeholder:opacity-20 focus:outline focus:outline-1 focus:outline-transparent',
					)}
					onChange={onChange}
					onFocus={onFocus}
					onBlur={onBlur}
					onClick={onClick}
					onKeyDown={onKeyDown} // Attach the onKeyDown handler to the input
					{...rest}
				/>
				{closeSearch && (
					<Icon
						className="text-body-md absolute right-4 cursor-pointer"
						size="md"
						name="cross"
						onClick={onCloseSearch}
					/>
				)}
			</div>
		</div>
	)
}

export default ClerkSearchInput
