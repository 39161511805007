import { Link } from '@remix-run/react'
import SeparatorLine from '#app/components/common/separator-line'
import Heading from '#app/components/typography/heading'
import { Icon } from '#app/components/ui/icon'
import { type IconName } from '@/icon-name'

interface ResponsiveNavItemProps {
	name: string
	iconName?: IconName
	isActive?: boolean
	handleOptionClick?: (option: any) => void
}

interface ResponsiveNavLinkItemProps {
	name: string
	iconName?: IconName
	link: string
}

interface NavItemContainerProps {
	iconName?: IconName
	name: string
	isActive?: boolean
}
export const ResponsiveNavItem = ({
	name,
	iconName,
	handleOptionClick,
	isActive,
}: ResponsiveNavItemProps) => {
	const handleClick = () => {
		if (handleOptionClick) {
			handleOptionClick(name)
		}
	}
	return (
		<div className="cursor-pointer" onClick={handleClick}>
			<NavItemContainer iconName={iconName} name={name} isActive={isActive} />
		</div>
	)
}

export const ResponsiveNavLinkItem = ({
	name,
	iconName,
	link,
}: ResponsiveNavLinkItemProps) => {
	return (
		<Link className="cursor-pointer" to={link}>
			<NavItemContainer iconName={iconName} name={name} />
		</Link>
	)
}

const NavItemContainer = ({
	iconName,
	name,
	isActive,
}: NavItemContainerProps) => {
	return (
		<div className="my-4 flex flex-col">
			<div className=" mb-6 flex w-full flex-row sm-devices:mb-3">
				{!!iconName && <Icon name={iconName} size="lg" className="mr-4" />}
				<div className="flex flex-grow">
					<Heading
						className={isActive ? 'font-semibold' : ''}
						as={'h5'}
						heading={name}
					></Heading>
				</div>

				{!iconName && <Icon name="chevron-right-arrow" size="lg" />}
				{isActive && <Icon name="check-circle" size="lg" />}
			</div>
			<SeparatorLine />
		</div>
	)
}
