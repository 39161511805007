import { Link } from '@remix-run/react'
import BlackBackgroundColorForImages from '#app/components/black-background-color-for-images'
import Body from '#app/components/typography/body'
import Heading from '#app/components/typography/heading'
import { Button } from '../../ui/button'

const PromotionCard = ({ promotion }: { promotion: any }) => {
	return (
		<div className=" group/promo relative col-span-2 hidden cursor-pointer items-end justify-self-end overflow-hidden rounded-2xl p-4 lg:flex lg:h-[430px] lg:w-[384px] xl:h-[430px] xl:w-[384px]">
			<div className="absolute left-0 top-0 z-[-10] w-full justify-center transition-transform group-hover/promo:scale-105">
				<img
					src={promotion.promoImage.filename}
					alt="Promotion"
					loading="lazy"
					decoding="async"
					fetchpriority="low"
					className="aspect-auto h-[300px] w-[250px] rounded-2xl object-cover transition-transform hover:scale-110 lg:h-[430px] lg:w-[384px] "
				/>
				<BlackBackgroundColorForImages />
				<div className="absolute left-0 top-0 z-10 h-full w-full rounded-2xl bg-gradient-to-t from-black-90 to-transparent opacity-30" />
			</div>
			<div>
				<Body
					body={promotion.promoSubHeading}
					as={'m'}
					className="text-white"
				/>
				<Heading heading={promotion.promoHeading} as={'h7'} textColor="white" />
				<Link to={promotion.promoButtonLink.cached_url}>
					<Button
						variant="secondary"
						className="mt-1 rounded-full border-none px-8 py-4 focus:text-black-90"
					>
						{promotion.promoButtonText}
					</Button>
				</Link>
			</div>
		</div>
	)
}

export default PromotionCard
