import { useNavigation } from '@remix-run/react'
import { useEffect, useState } from 'react'
import { Heading } from '#app/components/typography'
import { cn } from '#app/utils/misc'
import { type NavigationCategoriesStoryblok } from '#types/component-types-sb'
import CategoryGrid from './category-grid'

const SubMenu = ({ content }: { content: NavigationCategoriesStoryblok }) => {
	const location = useNavigation().location
	// Hide the submenu on URL change
	const [hideOnURLChange, setHideOnURLChange] = useState(false)
	// TODO: Find a better way to handle this close action
	useEffect(() => {
		setHideOnURLChange(true)
		const interval = setInterval(() => {
			setHideOnURLChange(false)
		}, 500)

		return () => {
			clearTimeout(interval)
			setHideOnURLChange(false)
		}
	}, [location?.pathname])

	const isNestedParentCategory =
		content &&
		content.categories &&
		content.categories[0] &&
		content.categories[0].categories &&
		content.categories[0].categories[0].component === 'parentCategory'

	const specialFirstColumn =
		content.title === 'WomenFuture' || content.title === 'MenFuture' //TODO: when new and sale is figured out, remove 'Future'

	return (
		<div
			className={cn(
				'invisible absolute right-0 z-20 w-full rounded-b-2xl bg-white opacity-0 shadow-sm transition-all delay-75 hover:visible hover:opacity-100 peer-hover:visible peer-hover:opacity-100 sm:top-[72px]',
				isNestedParentCategory ? 'h-[620px]' : 'h-[550px]',
				hideOnURLChange && 'invisible hidden opacity-0',
			)}
		>
			{isNestedParentCategory && (
				<div className="px-4 py-8 lg:px-16">
					<KidsSubMenu content={content} />{' '}
				</div>
			)}

			{!isNestedParentCategory && (
				<div className="px-4 py-8 lg:p-16">
					<CategoryGrid
						content={content}
						specialFirstColumn={specialFirstColumn}
					/>
				</div>
			)}
		</div>
	)
}

const KidsSubMenu = ({ content }: { content: any }) => {
	const [selectedTab, setSelectedTab] = useState(content.categories[0].title)

	const selected = content.categories.find(
		(item: any) => item.title === selectedTab,
	)
	return (
		<>
			<div className="mb-8 flex flex-row justify-between border-y border-beige-50 py-6">
				{content.categories.map((item: any) => (
					<div key={item._uid} className="group/tabs">
						<div
							className={cn(
								'mb cursor-pointer',
								selectedTab === item.title && 'font-bold',
							)}
							onClick={() => setSelectedTab(item.title)}
						>
							<Heading
								heading={item.title}
								as={'h7'}
								className="color-black-90"
							/>

							<div
								className={cn(
									'h-[2px] w-0 rounded bg-beige-90 transition-all group-hover/tabs:w-full',
									selectedTab === item.title ? 'w-full' : 'w-0',
								)}
							></div>
						</div>
					</div>
				))}
			</div>
			<CategoryGrid content={selected} />
		</>
	)
}

export default SubMenu
