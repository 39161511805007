import { Link } from '@remix-run/react'
import Body from '#app/components/typography/body'
import Heading from '#app/components/typography/heading'
import { Icon } from '#app/components/ui/icon'
import { useUtmParams } from '#app/hooks/useUtmParams'
import { trackBrandView } from '#app/utils/tracking/amplitude/brand-view'
import PromotionCard from './promotion-card'

const CategoryGrid = ({
	content,
	specialFirstColumn,
}: {
	content: any
	specialFirstColumn?: boolean
}) => {
	const utmParams = useUtmParams()

	return (
		<div className="grid auto-cols-auto grid-flow-col">
			{content.categories.map((item: any, columnIndex: number) => (
				<div key={item._uid}>
					<Link to={item.link.cached_url} className="mb-2">
						<Heading heading={item.title} as={'h7'} className="text-[18px]" />
					</Link>

					{item.categories.map((subItem: any) => (
						<Link key={subItem._uid} to={subItem.link.cached_url}>
							{specialFirstColumn && columnIndex === 0 ? (
								<Heading
									heading={subItem.title}
									as={'h7'}
									className="text-[18px] text-red"
								/>
							) : (
								<div className="group/sub my-2 mr-4 flex flex-row">
									<Body
										body={subItem.title}
										onClick={() => trackBrandView(subItem.link, utmParams)}
										as={'m'}
										className="text-black-70 opacity-70 group-hover/sub:text-black-90 group-hover/sub:opacity-100"
									/>
									<Icon
										name="chevron-right-arrow"
										size="md"
										className="opacity-0 transition-all group-hover/sub:translate-x-1 group-hover/sub:opacity-100"
									/>
								</div>
							)}
						</Link>
					))}
				</div>
			))}

			{content.promotions && content.promotions[0] && (
				<PromotionCard promotion={content.promotions[0]} />
			)}
		</div>
	)
}

export default CategoryGrid
